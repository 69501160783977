import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router';
import { checkUserAuthAndTerms } from '~/shared/auth/auth-utils';

export const deviceClaimLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    await checkUserAuthAndTerms(queryClient, request);

    return null;
  };

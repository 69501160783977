export const createRoutes = {
  ChangePassword: '/change-password',
  Device: '/devices/:deviceId',
  DeviceAccessKeys: '/devices/:deviceId/access-keys',
  DeviceClaim: '/devices/claim',
  DeviceClaims: '/devices/:deviceId/claims',
  DeviceFirmware: '/devices/:deviceId/firmware',
  DeviceRaw: '/devices/:deviceId/raw-device',
  DeviceRemoteConnection: '/devices/:deviceId/remote-connection',
  Devices: '/devices',
  Firmware: '/firmware',
  ForgotPassword: '/forgot-password',
  ForgotPasswordConfirm: '/forgot-password-confirm',
  NotFound: '*',
  Register: '/register',
  RequestInstallerAccess: '/request-installer-access',
  Settings: '/settings',
  SignIn: '/signin',
  Support: '/support',
  TermsConditions: '/terms-conditions',
  Users: '/users',
  UsersAdd: '/users/add',
};

type RouteKeys = keyof typeof createRoutes;
export type RouteValues = (typeof createRoutes)[RouteKeys];

export const createDeviceRoute = (deviceName: string) => `/${deviceName}`;

export const createDeviceRawDeviceRoute = (deviceName: string) =>
  `/${deviceName}/raw-device`;

export const createDeviceFirmwareRoute = (deviceName: string) =>
  `/${deviceName}/firmware`;

export const createDeviceEventsRoute = (deviceName: string) =>
  `/${deviceName}/events`;

export const createDeviceClaimsRoute = (deviceName: string) =>
  `/${deviceName}/claims`;

export const createDeviceAccessKeysRoute = (deviceName: string) =>
  `/${deviceName}/access-keys`;

export const createDeviceRemoteConnectionRoute = (deviceName: string) =>
  `/${deviceName}/remote-connection`;

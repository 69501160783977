import { Box, Button, Grid2 } from '@mui/material';
import { Link as RouterLink, Outlet } from 'react-router';
import { useTranslation } from 'react-i18next';
import Logo from '~/pages/layout/images/logo.png';
import LoginBackground from '~/pages/layout/images/login-background.png';
import { createRoutes } from '~/routes';
import { DRAWER_WIDTH } from '~/shared/contants/layout-constants';
import { showCookiePreferences } from '~/services/cookiebar/onetrust';

export function AuthLayout() {
  const { t } = useTranslation();

  return (
    <Grid2
      container
      minWidth="100%"
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
      p={4}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: DRAWER_WIDTH,
          height: 'auto',
        }}
      >
        <RouterLink to={createRoutes.SignIn}>
          <Box
            component="img"
            src={Logo}
            alt="Wavin logo"
            sx={{
              px: 6,
              py: 10,
              width: '100%',
              height: 'auto',
            }}
          />
        </RouterLink>
      </Box>

      <Box
        position="fixed"
        top={(theme) => theme.spacing(4)}
        right={(theme) => theme.spacing(2)}
      >
        <Button onClick={showCookiePreferences}>
          {t('termsPage.privacyPreferencesTitle')}
        </Button>
      </Box>

      <Outlet />

      <Box
        component="img"
        src={LoginBackground}
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          zIndex: -1,
        }}
      />
    </Grid2>
  );
}

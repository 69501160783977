import { createConnectTransport } from '@connectrpc/connect-web';
import { Interceptor } from '@connectrpc/connect';
import { authInterceptor } from '~/services/auth/auth-interceptor';
import {
  BLAZE_TRANSPORT_URL,
  ENABLE_MOCKS,
  IGNITE_TRANSPORT_URL,
} from '~/services/environment/environment';

const igniteBaseUrl = IGNITE_TRANSPORT_URL;
const blazeBaseUrl = BLAZE_TRANSPORT_URL;

const interceptors: Interceptor[] = [authInterceptor];

export const igniteTransport = createConnectTransport({
  useBinaryFormat: !ENABLE_MOCKS,
  baseUrl: igniteBaseUrl,
  interceptors,
});

export const blazeTransport = createConnectTransport({
  useBinaryFormat: !ENABLE_MOCKS,
  baseUrl: blazeBaseUrl,
  interceptors,
});

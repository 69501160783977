import { wrapCreateBrowserRouterV7 } from '@sentry/react';
import { createBrowserRouter, redirect, RouteObject } from 'react-router';
import React from 'react';
import { createRoutes } from '~/routes';
import { AuthLayout } from '~/pages/layout/AuthLayout';
import { signInAction } from '~/pages/SignIn/SignInPage.action';
import { forgetPasswordAction } from '~/pages/ForgotPassword/ForgotPasswordPage.action';
import { forgetPasswordConfirmAction } from '~/pages/ForgotPasswordConfirm/ForgotPasswordConfirmPage.action';
import { authService } from '~/services/auth/auth-service';
import { queryClient } from '~/shared/api/query-client';
import { appContentLoader } from '~/pages/layout/AppContent.loader';
import { AppLayout } from '~/pages/layout/AppLayout';
import { AppContent } from '~/pages/layout/AppContent';
import { devicesListPageLoader } from './pages/DevicesList/DevicesListPage.loader';
import ErrorPage from '~/pages/ErrorPage/ErrorPage';
import { devicePageLoader } from '~/pages/Device/DevicePage.loader';
import { deviceClaimsLoader } from '~/pages/Device/DeviceClaims.loader';
import { deviceAccessKeysLoader } from '~/pages/Device/DeviceAccessKeys.loader';
import { firmwareListPageLoader } from '~/shared/api/FirmwaresListPage.loader';
import { usersListPageLoader } from '~/pages/UsersList/api/usersListPage.loader';
import { registerAction } from '~/pages/Register/RegisterPage.action';
import { changePasswordAction } from '~/pages/ChangePassword/ChangePasswordPage.action';
import { deviceClaimLoader } from '~/pages/DeviceClaim/DeviceClaimPage.loader';
import { installerCheckPageAction } from '~/pages/InstallerCheckPage/InstallerCheckPage.action';
import { installerCheckPageLoader } from '~/pages/InstallerCheckPage/installerCheckPage.loader';
import { settingsPageLoader } from '~/pages/SettingsPage/settingsPage.loader';
import { termsConditionsPageLoader } from '~/pages/TermsConditions/TermsConditionsPage.loader';

/// This is to handle error when importing a component dynamically (lazy loading, fails if any chunk of the component is missing)
/// It can happen when the new version of the app is deployed and the user is still using the old version
/// The solution is to reload the page to load the new version and assets
/// The reload is a bit aggressive, but it only catches the error when the component is not found
/// It does not catch other errors like network error, etc.
async function importOrReload(
  dynamicImportFn: () => Promise<any>,
): Promise<any> {
  try {
    return dynamicImportFn();
  } catch {
    window.location.reload();
    return null;
  }
}

const BrokerValuesTable = React.lazy(() =>
  importOrReload(() => import('~/pages/Device/sections/BrokerValuesTable')),
);
const ChangePasswordPage = React.lazy(() =>
  importOrReload(() => import('~/pages/ChangePassword/ChangePasswordPage')),
);
const DeviceAccessKeys = React.lazy(() =>
  importOrReload(() => import('~/pages/Device/sections/DeviceAccessKeysTable')),
);
const DevicePage = React.lazy(() =>
  importOrReload(() => import('~/pages/Device/DevicePage')),
);
const DeviceClaims = React.lazy(() =>
  importOrReload(() => import('~/pages/Device/sections/DeviceClaims')),
);
const DeviceClaimPage = React.lazy(() =>
  importOrReload(() => import('~/pages/DeviceClaim/DeviceClaimPage')),
);
const DevicesListPage = React.lazy(() =>
  importOrReload(() => import('~/pages/DevicesList/DevicesListPage')),
);
const FirmwareConfig = React.lazy(() =>
  importOrReload(
    () => import('~/pages/Device/sections/FirmwareConfig/FirmwareConfig'),
  ),
);
const FirmwareListPage = React.lazy(() =>
  importOrReload(() => import('~/pages/FirmwareList/FirmwareListPage')),
);
const ForgotPasswordPage = React.lazy(() =>
  importOrReload(() => import('~/pages/ForgotPassword/ForgotPasswordPage')),
);
const ForgotPasswordConfirmPage = React.lazy(() =>
  importOrReload(
    () => import('~/pages/ForgotPasswordConfirm/ForgotPasswordConfirmPage'),
  ),
);
const DeviceRemoteConnectionPage = React.lazy(() =>
  importOrReload(
    () => import('~/pages/Device/sections/DeviceRemoteConnection'),
  ),
);
const NotFoundPage = React.lazy(() =>
  importOrReload(() => import('~/pages/NotFound/NotFoundPage')),
);
const RawDeviceOutput = React.lazy(() =>
  importOrReload(() => import('~/pages/Device/sections/RawDeviceOutput')),
);
const SignInPage = React.lazy(() =>
  importOrReload(() => import('~/pages/SignIn/SignInPage')),
);
const SettingsPage = React.lazy(() =>
  importOrReload(() => import('~/pages/SettingsPage/SettingsPage')),
);
const InstallerCheckPage = React.lazy(() =>
  importOrReload(() => import('~/pages/InstallerCheckPage/InstallerCheckPage')),
);
const SupportPage = React.lazy(() =>
  importOrReload(() => import('~/pages/Support/SupportPage')),
);
const UsersListPage = React.lazy(() =>
  importOrReload(() => import('~/pages/UsersList/UsersListPage')),
);
const RegisterPage = React.lazy(() =>
  importOrReload(() => import('~/pages/Register/RegisterPage')),
);
const TermsConditionsPage = React.lazy(() =>
  importOrReload(() => import('~/pages/TermsConditions/TermsConditionsPage')),
);

export const routesConfig: RouteObject[] = [
  {
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: createRoutes.SignIn,
        element: <SignInPage />,
        action: signInAction(authService),
      },
      {
        path: createRoutes.ForgotPassword,
        element: <ForgotPasswordPage />,
        errorElement: <ForgotPasswordPage />,
        action: forgetPasswordAction(authService),
      },
      {
        path: createRoutes.ForgotPasswordConfirm,
        element: <ForgotPasswordConfirmPage />,
        errorElement: <ForgotPasswordConfirmPage />,
        action: forgetPasswordConfirmAction(authService),
      },
      {
        path: createRoutes.Register,
        element: <RegisterPage />,
        action: registerAction(authService),
      },
    ],
  },
  {
    element: (
      <AppLayout>
        <AppContent />
      </AppLayout>
    ),
    errorElement: <ErrorPage />,
    loader: appContentLoader(queryClient),
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            path: '/',
            loader: () => redirect(createRoutes.Devices),
          },
          {
            path: createRoutes.TermsConditions,
            element: <TermsConditionsPage />,
            loader: termsConditionsPageLoader(queryClient),
          },
          {
            path: createRoutes.Support,
            element: <SupportPage />,
          },
          {
            path: createRoutes.Devices,
            element: <DevicesListPage />,
            loader: devicesListPageLoader(queryClient),
          },
          {
            path: createRoutes.DeviceClaim,
            element: <DeviceClaimPage />,
            loader: deviceClaimLoader(queryClient),
          },
          {
            element: <DevicePage />,
            loader: devicePageLoader(queryClient),
            children: [
              {
                path: createRoutes.Device,
                element: <BrokerValuesTable />,
              },
              {
                path: createRoutes.DeviceRaw,
                element: <RawDeviceOutput />,
              },
              {
                path: createRoutes.DeviceRemoteConnection,
                element: <DeviceRemoteConnectionPage />,
              },
              {
                path: createRoutes.DeviceFirmware,
                loader: firmwareListPageLoader(queryClient),
                element: <FirmwareConfig />,
              },
              {
                path: createRoutes.DeviceClaims,
                loader: deviceClaimsLoader(queryClient),
                element: <DeviceClaims />,
              },
              {
                path: createRoutes.DeviceAccessKeys,
                loader: deviceAccessKeysLoader(queryClient),
                element: <DeviceAccessKeys />,
              },
            ],
          },
          {
            path: createRoutes.Firmware,
            element: <FirmwareListPage />,
            loader: firmwareListPageLoader(queryClient),
          },
          {
            path: createRoutes.Users,
            element: <UsersListPage />,
            loader: usersListPageLoader(queryClient),
          },
          {
            path: createRoutes.Settings,
            element: <SettingsPage />,
            loader: settingsPageLoader(queryClient),
          },
          {
            path: createRoutes.RequestInstallerAccess,
            element: <InstallerCheckPage />,
            action: installerCheckPageAction(queryClient),
            loader: installerCheckPageLoader(queryClient),
          },
          {
            path: createRoutes.ChangePassword,
            element: <ChangePasswordPage />,
            action: changePasswordAction(authService),
          },
          {
            path: createRoutes.NotFound,
            element: <NotFoundPage />,
          },
        ],
      },
    ],
  },
];

export function createRouter() {
  return wrapCreateBrowserRouterV7(createBrowserRouter)(routesConfig);
}

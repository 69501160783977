import {
  captureConsoleIntegration,
  init,
  makeFetchTransport,
  reactRouterV6BrowserTracingIntegration,
  withScope,
} from '@sentry/react';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import {
  BUILD_ID,
  ENVIRONMENT,
  RELEASE_VERSION,
  SENTRY_DSN,
  BLAZE_TRANSPORT_URL,
  IGNITE_TRANSPORT_URL,
} from '~/services/environment/environment';
import { ConsentTransport } from './ConsentTransport';

let transport: ConsentTransport | undefined;

const createSentryTracePropagationTarget = (url: string) => {
  const escapedUrl = url.replace(/\./g, '\\.').replace(/\//g, '\\/');
  return new RegExp(`^${escapedUrl}`);
};

export function initErrorCapturing({
  sentryDsn = SENTRY_DSN,
  buildId = BUILD_ID,
} = {}) {
  if (!sentryDsn) {
    return;
  }
  init({
    environment: ENVIRONMENT,
    dsn: sentryDsn,
    transport: (options) => {
      const newTransport = new ConsentTransport(makeFetchTransport(options));
      transport = newTransport;
      return transport;
    },
    release: RELEASE_VERSION,
    tracePropagationTargets: [
      createSentryTracePropagationTarget(BLAZE_TRANSPORT_URL),
      createSentryTracePropagationTarget(IGNITE_TRANSPORT_URL),
    ],
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: 1.0,
  });

  if (buildId) {
    withScope((scope) => {
      scope.setTag('build_id', buildId);
    });
  }
}

export function setErrorCapturingConsent(hasConsent: boolean) {
  transport?.setConsent(hasConsent);
}

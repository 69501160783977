import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router';
import {
  checkUserAuthAndTerms,
  doesUserHaveRequiredRole,
} from '~/shared/auth/auth-utils';
import { getDeviceQuery } from '../../shared/api/getDevice.queries';
import { DeviceModel } from '~/shared/models/device/DeviceModel';
import { assertIsDefined } from '~/types/assert-type';
import { RoleModel } from '~/shared/models/role/RoleModel';
import { Role } from '~/types/wavin/ignite/v1/user_pb';

export type DevicePageLoaderData = DeviceModel;

export const devicePageLoader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    const { deviceId } = params;
    assertIsDefined(deviceId);

    await checkUserAuthAndTerms(queryClient, request);

    const hasConfigEditorRole = await doesUserHaveRequiredRole(
      queryClient,
      new RoleModel(Role.CONFIG_EDITOR),
    );

    return await queryClient.ensureQueryData(
      getDeviceQuery({ deviceId, includeSource: hasConfigEditorRole }),
    );
  };

import { ConnectError } from '@connectrpc/connect';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { DEVELOPMENT_MODE } from '~/services/environment/environment';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      // eslint-disable-next-line no-restricted-syntax
      Sentry.captureException(error, {
        tags: {
          queryKey: `${query.queryKey}`,
          errorCode: error instanceof ConnectError ? error.code : null,
        },
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, _, mutation) => {
      // eslint-disable-next-line no-restricted-syntax
      Sentry.captureException(error, {
        tags: {
          mutationKey: `${mutation.options.mutationKey}`,
          errorCode: error instanceof ConnectError ? error.code : null,
          variables: JSON.stringify(variables),
        },
      });
    },
  }),
  defaultOptions: {
    queries: {
      ...(DEVELOPMENT_MODE && { retry: false }),
      // avoid duplicate data fetching in utils and component
      staleTime: 1_000,
    },
    mutations: {
      throwOnError: true,
    },
  },
});

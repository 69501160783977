import { createCallbackClient, createClient } from '@connectrpc/connect';
import { blazeTransport } from '~/services/api/service-transport';
import { BlazeDeviceService } from '~/types/wavin/blaze/v1/blaze_device_service_pb';

export const blazeDevicePromiseClient = createClient(
  BlazeDeviceService,
  blazeTransport,
);

export const blazeDeviceClientCallback = createCallbackClient(
  BlazeDeviceService,
  blazeTransport,
);

// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file wavin/ignite/v1/ignite_user_service.proto (package wavin.ignite.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../../buf/validate/validate_pb";
import type { EmptySchema } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_empty } from "@bufbuild/protobuf/wkt";
import type { Role, User, UserSchema } from "./user_pb";
import { file_wavin_ignite_v1_user } from "./user_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file wavin/ignite/v1/ignite_user_service.proto.
 */
export const file_wavin_ignite_v1_ignite_user_service: GenFile = /*@__PURE__*/
  fileDesc("Cil3YXZpbi9pZ25pdGUvdjEvaWduaXRlX3VzZXJfc2VydmljZS5wcm90bxIPd2F2aW4uaWduaXRlLnYxIjAKEURlbGV0ZVVzZXJSZXF1ZXN0EhsKBG5hbWUYASABKAlCDbpICnIIOgZ1c2Vycy8iLQoOR2V0VXNlclJlcXVlc3QSGwoEbmFtZRgBIAEoCUINukgKcgg6BnVzZXJzLyI3ChBQYXRjaFVzZXJSZXF1ZXN0EiMKBHVzZXIYASABKAsyFS53YXZpbi5pZ25pdGUudjEuVXNlciJHChBMaXN0VXNlcnNSZXF1ZXN0EhEKCXBhZ2Vfc2l6ZRgBIAEoAxISCgpwYWdlX3Rva2VuGAIgASgJEgwKBHNraXAYAyABKAMiUgoRTGlzdFVzZXJzUmVzcG9uc2USJAoFdXNlcnMYASADKAsyFS53YXZpbi5pZ25pdGUudjEuVXNlchIXCg9uZXh0X3BhZ2VfdG9rZW4YAiABKAkiWAoTU2V0VXNlclJvbGVzUmVxdWVzdBIbCgRuYW1lGAEgASgJQg26SApyCDoGdXNlcnMvEiQKBXJvbGVzGAIgAygOMhUud2F2aW4uaWduaXRlLnYxLlJvbGUiMgoVR2V0VXNlckJ5RW1haWxSZXF1ZXN0EhkKBWVtYWlsGAEgASgJQgq6SAfIAQFyAmABMuYDChFJZ25pdGVVc2VyU2VydmljZRJKCgpEZWxldGVVc2VyEiIud2F2aW4uaWduaXRlLnYxLkRlbGV0ZVVzZXJSZXF1ZXN0GhYuZ29vZ2xlLnByb3RvYnVmLkVtcHR5IgASQwoHR2V0VXNlchIfLndhdmluLmlnbml0ZS52MS5HZXRVc2VyUmVxdWVzdBoVLndhdmluLmlnbml0ZS52MS5Vc2VyIgASRwoJUGF0Y2hVc2VyEiEud2F2aW4uaWduaXRlLnYxLlBhdGNoVXNlclJlcXVlc3QaFS53YXZpbi5pZ25pdGUudjEuVXNlciIAElQKCUxpc3RVc2VycxIhLndhdmluLmlnbml0ZS52MS5MaXN0VXNlcnNSZXF1ZXN0GiIud2F2aW4uaWduaXRlLnYxLkxpc3RVc2Vyc1Jlc3BvbnNlIgASUQoOR2V0VXNlckJ5RW1haWwSJi53YXZpbi5pZ25pdGUudjEuR2V0VXNlckJ5RW1haWxSZXF1ZXN0GhUud2F2aW4uaWduaXRlLnYxLlVzZXIiABJOCgxTZXRVc2VyUm9sZXMSJC53YXZpbi5pZ25pdGUudjEuU2V0VXNlclJvbGVzUmVxdWVzdBoWLmdvb2dsZS5wcm90b2J1Zi5FbXB0eSIAQosBChNjb20ud2F2aW4uaWduaXRlLnYxQhZJZ25pdGVVc2VyU2VydmljZVByb3RvUAGiAgNXSViqAg9XYXZpbi5JZ25pdGUuVjHKAg9XYXZpblxJZ25pdGVcVjHiAhtXYXZpblxJZ25pdGVcVjFcR1BCTWV0YWRhdGHqAhFXYXZpbjo6SWduaXRlOjpWMWIGcHJvdG8z", [file_buf_validate_validate, file_google_protobuf_empty, file_wavin_ignite_v1_user]);

/**
 * @generated from message wavin.ignite.v1.DeleteUserRequest
 */
export type DeleteUserRequest = Message<"wavin.ignite.v1.DeleteUserRequest"> & {
  /**
   * Resource name of user, format: users/{usersID}
   *
   * @generated from field: string name = 1;
   */
  name: string;
};

/**
 * Describes the message wavin.ignite.v1.DeleteUserRequest.
 * Use `create(DeleteUserRequestSchema)` to create a new message.
 */
export const DeleteUserRequestSchema: GenMessage<DeleteUserRequest> = /*@__PURE__*/
  messageDesc(file_wavin_ignite_v1_ignite_user_service, 0);

/**
 * @generated from message wavin.ignite.v1.GetUserRequest
 */
export type GetUserRequest = Message<"wavin.ignite.v1.GetUserRequest"> & {
  /**
   * Resource name of user, format: users/{usersID}
   *
   * @generated from field: string name = 1;
   */
  name: string;
};

/**
 * Describes the message wavin.ignite.v1.GetUserRequest.
 * Use `create(GetUserRequestSchema)` to create a new message.
 */
export const GetUserRequestSchema: GenMessage<GetUserRequest> = /*@__PURE__*/
  messageDesc(file_wavin_ignite_v1_ignite_user_service, 1);

/**
 * @generated from message wavin.ignite.v1.PatchUserRequest
 */
export type PatchUserRequest = Message<"wavin.ignite.v1.PatchUserRequest"> & {
  /**
   * @generated from field: wavin.ignite.v1.User user = 1;
   */
  user?: User;
};

/**
 * Describes the message wavin.ignite.v1.PatchUserRequest.
 * Use `create(PatchUserRequestSchema)` to create a new message.
 */
export const PatchUserRequestSchema: GenMessage<PatchUserRequest> = /*@__PURE__*/
  messageDesc(file_wavin_ignite_v1_ignite_user_service, 2);

/**
 * @generated from message wavin.ignite.v1.ListUsersRequest
 */
export type ListUsersRequest = Message<"wavin.ignite.v1.ListUsersRequest"> & {
  /**
   * Requested page size. Server may return fewer items than requested.
   * If unspecified, server will pick an appropriate default.
   *
   * @generated from field: int64 page_size = 1;
   */
  pageSize: bigint;

  /**
   * A token identifying a page of results the server should return.
   *
   * @generated from field: string page_token = 2;
   */
  pageToken: string;

  /**
   * Number of results to skip after page_token
   *
   * @generated from field: int64 skip = 3;
   */
  skip: bigint;
};

/**
 * Describes the message wavin.ignite.v1.ListUsersRequest.
 * Use `create(ListUsersRequestSchema)` to create a new message.
 */
export const ListUsersRequestSchema: GenMessage<ListUsersRequest> = /*@__PURE__*/
  messageDesc(file_wavin_ignite_v1_ignite_user_service, 3);

/**
 * @generated from message wavin.ignite.v1.ListUsersResponse
 */
export type ListUsersResponse = Message<"wavin.ignite.v1.ListUsersResponse"> & {
  /**
   * @generated from field: repeated wavin.ignite.v1.User users = 1;
   */
  users: User[];

  /**
   * A token to retrieve next page of results.
   *
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken: string;
};

/**
 * Describes the message wavin.ignite.v1.ListUsersResponse.
 * Use `create(ListUsersResponseSchema)` to create a new message.
 */
export const ListUsersResponseSchema: GenMessage<ListUsersResponse> = /*@__PURE__*/
  messageDesc(file_wavin_ignite_v1_ignite_user_service, 4);

/**
 * @generated from message wavin.ignite.v1.SetUserRolesRequest
 */
export type SetUserRolesRequest = Message<"wavin.ignite.v1.SetUserRolesRequest"> & {
  /**
   * Resource name of user, format: users/{usersID}
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * Roles of users will be set to given roles. Empty means no roles for user.
   *
   * @generated from field: repeated wavin.ignite.v1.Role roles = 2;
   */
  roles: Role[];
};

/**
 * Describes the message wavin.ignite.v1.SetUserRolesRequest.
 * Use `create(SetUserRolesRequestSchema)` to create a new message.
 */
export const SetUserRolesRequestSchema: GenMessage<SetUserRolesRequest> = /*@__PURE__*/
  messageDesc(file_wavin_ignite_v1_ignite_user_service, 5);

/**
 * @generated from message wavin.ignite.v1.GetUserByEmailRequest
 */
export type GetUserByEmailRequest = Message<"wavin.ignite.v1.GetUserByEmailRequest"> & {
  /**
   * Email of user
   *
   * @generated from field: string email = 1;
   */
  email: string;
};

/**
 * Describes the message wavin.ignite.v1.GetUserByEmailRequest.
 * Use `create(GetUserByEmailRequestSchema)` to create a new message.
 */
export const GetUserByEmailRequestSchema: GenMessage<GetUserByEmailRequest> = /*@__PURE__*/
  messageDesc(file_wavin_ignite_v1_ignite_user_service, 6);

/**
 * IgniteUserService is the service that is responsible for user information
 *
 * @generated from service wavin.ignite.v1.IgniteUserService
 */
export const IgniteUserService: GenService<{
  /**
   * Deletes a user account
   *
   * @generated from rpc wavin.ignite.v1.IgniteUserService.DeleteUser
   */
  deleteUser: {
    methodKind: "unary";
    input: typeof DeleteUserRequestSchema;
    output: typeof EmptySchema;
  },
  /**
   * Retrieves a user account
   *
   * @generated from rpc wavin.ignite.v1.IgniteUserService.GetUser
   */
  getUser: {
    methodKind: "unary";
    input: typeof GetUserRequestSchema;
    output: typeof UserSchema;
  },
  /**
   * Patches a user account
   *
   * @generated from rpc wavin.ignite.v1.IgniteUserService.PatchUser
   */
  patchUser: {
    methodKind: "unary";
    input: typeof PatchUserRequestSchema;
    output: typeof UserSchema;
  },
  /**
   * List users with roles (admin role only)
   *
   * @generated from rpc wavin.ignite.v1.IgniteUserService.ListUsers
   */
  listUsers: {
    methodKind: "unary";
    input: typeof ListUsersRequestSchema;
    output: typeof ListUsersResponseSchema;
  },
  /**
   * Get user by email (admin role only)
   *
   * @generated from rpc wavin.ignite.v1.IgniteUserService.GetUserByEmail
   */
  getUserByEmail: {
    methodKind: "unary";
    input: typeof GetUserByEmailRequestSchema;
    output: typeof UserSchema;
  },
  /**
   * Set roles for a user account (admin role only)
   *
   * @generated from rpc wavin.ignite.v1.IgniteUserService.SetUserRoles
   */
  setUserRoles: {
    methodKind: "unary";
    input: typeof SetUserRolesRequestSchema;
    output: typeof EmptySchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_wavin_ignite_v1_ignite_user_service, 0);


import { QueryClient } from '@tanstack/react-query';
import { LoaderFunction, LoaderFunctionArgs, redirect } from 'react-router';
import { createRoutes } from '~/routes';
import { getAuthUser } from '~/shared/auth/auth-utils';
import { hasAcceptedTerms } from '~/shared/hooks/useUserHasAcceptedTerms';

export const termsConditionsPageLoader =
  (queryClient: QueryClient): LoaderFunction =>
  async ({ request }: LoaderFunctionArgs) => {
    const user = await getAuthUser(queryClient, request);

    if (hasAcceptedTerms(user.termsAcceptedVersion)) {
      return redirect(createRoutes.Devices);
    }

    return null;
  };

import { ActionFunction, redirect } from 'react-router';
import { AuthService } from '~/services/auth/AuthService';
import { createRoutes } from '~/routes';
import { validatePassword } from './validatePassword';

export type CreateNewPasswordFormData = {
  password: string;
  passwordConfirm: string;
  oobCode: string;
};

export const forgetPasswordConfirmAction =
  (auth: AuthService): ActionFunction =>
  async ({ request }) => {
    const formData = await request.formData();
    const { password, passwordConfirm, oobCode } = Object.fromEntries(
      formData.entries(),
    ) as CreateNewPasswordFormData;

    validatePassword(password, passwordConfirm, oobCode);
    await auth.confirmPasswordReset(oobCode, password);

    return redirect(createRoutes.SignIn);
  };

// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file wavin/blaze/v1/device_access_key.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../../buf/validate/validate_pb";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { User } from "./user_pb";
import { file_wavin_blaze_v1_user } from "./user_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file wavin/blaze/v1/device_access_key.proto.
 */
export const file_wavin_blaze_v1_device_access_key: GenFile = /*@__PURE__*/
  fileDesc("CiZ3YXZpbi9ibGF6ZS92MS9kZXZpY2VfYWNjZXNzX2tleS5wcm90bxIOd2F2aW4uYmxhemUudjEikgIKD0RldmljZUFjY2Vzc0tleRIMCgRuYW1lGAEgASgJEi8KC2NyZWF0ZV90aW1lGAIgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcBJACg9leHBpcmF0aW9uX3RpbWUYAyABKAsyGi5nb29nbGUucHJvdG9idWYuVGltZXN0YW1wQgu6SAjIAQGyAQJAARJGChVjbGFpbV9leHBpcmF0aW9uX3RpbWUYBCABKAsyGi5nb29nbGUucHJvdG9idWYuVGltZXN0YW1wQgu6SAjIAQGyAQJAARISCgphY2Nlc3Nfa2V5GAUgASgJEiIKBHVzZXIYBiABKAsyFC53YXZpbi5ibGF6ZS52MS5Vc2VyQoQBChJjb20ud2F2aW4uYmxhemUudjFCFERldmljZUFjY2Vzc0tleVByb3RvUAGiAgNXQliqAg5XYXZpbi5CbGF6ZS5WMcoCDldhdmluXEJsYXplXFYx4gIaV2F2aW5cQmxhemVcVjFcR1BCTWV0YWRhdGHqAhBXYXZpbjo6QmxhemU6OlYxYgZwcm90bzM", [file_buf_validate_validate, file_google_protobuf_timestamp, file_wavin_blaze_v1_user]);

/**
 * A device access key is generated key to allow a user to claim a device
 *
 * @generated from message wavin.blaze.v1.DeviceAccessKey
 */
export type DeviceAccessKey = Message<"wavin.blaze.v1.DeviceAccessKey"> & {
  /**
   * DeviceAccessKey resource name, format: devices/{device}/access-keys/{access-key}
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 2;
   */
  createTime?: Timestamp;

  /**
   * Expiration time of the access key
   *
   * @generated from field: google.protobuf.Timestamp expiration_time = 3;
   */
  expirationTime?: Timestamp;

  /**
   * Expiration time of the claim that is created by this access key
   *
   * @generated from field: google.protobuf.Timestamp claim_expiration_time = 4;
   */
  claimExpirationTime?: Timestamp;

  /**
   * The generated access_key: format XXXX-XXXX-XXXX-XXXX
   *
   * @generated from field: string access_key = 5;
   */
  accessKey: string;

  /**
   * User who created the access key
   *
   * @generated from field: wavin.blaze.v1.User user = 6;
   */
  user?: User;
};

/**
 * Describes the message wavin.blaze.v1.DeviceAccessKey.
 * Use `create(DeviceAccessKeySchema)` to create a new message.
 */
export const DeviceAccessKeySchema: GenMessage<DeviceAccessKey> = /*@__PURE__*/
  messageDesc(file_wavin_blaze_v1_device_access_key, 0);


import { Property } from '~/types/wavin/sentio/v1/device_config_source_pb';

export type PropertyValue = Property['value'];

export class ValueModel {
  displayValue: string | number | Uint8Array | undefined;

  displayHexValue: string | number | Uint8Array | undefined;

  value: PropertyValue;

  constructor(value: PropertyValue) {
    this.value = value;
    this.displayValue = ValueModel.createDisplayValue(value);
    this.displayHexValue = ValueModel.createDisplayHexValue(value);
  }

  static createDisplayHexValue(propertyValue: PropertyValue) {
    const maskValue = (value: number, padSize: number) =>
      value
        .toString(16)
        .padStart(padSize, '0')
        .toUpperCase()
        .replace(/(.{2})/g, '$1-') // Add a dash after every two characters
        .replace(/-$/, ''); // Remove the last dash

    const { value, case: valueCase } = propertyValue;
    switch (valueCase) {
      case 'valU1':
      case 'valD1':
        return maskValue(value, 2);
      case 'valU2':
      case 'valD2':
        return maskValue(value, 4);
      case 'valD4':
      case 'valU4':
        return maskValue(value, 8);
      case 'valText':
        return value
          .split('')
          .map((char) => char.charCodeAt(0).toString(16).toUpperCase())
          .join('-');
      case 'valData': {
        const uint = new Uint8Array(value);
        return Array.from(uint)
          .map((byte) => byte.toString(16).padStart(2, '0').toUpperCase())
          .join('-');
      }
      case undefined:
      case 'valServiceCommand':
      case 'valD2Fp10':
      case 'valU2Fp10':
      case 'valD2Fp100':
      case 'valU2Fp100':
      default:
        return '-';
    }
  }

  static createDisplayValue(propertyValue: PropertyValue) {
    const { value, case: valueCase } = propertyValue;
    switch (valueCase) {
      case 'valD1':
      case 'valD2':
      case 'valD4':
      case 'valU1':
      case 'valU2':
      case 'valU4':
        return value.toString();

      case 'valText':
      case 'valServiceCommand':
        return value;

      case 'valD2Fp10':
      case 'valU2Fp10':
        return (value / 10).toFixed(1);

      case 'valD2Fp100':
      case 'valU2Fp100':
        return (value / 100).toFixed(2);

      case undefined:
      case 'valData':
      default:
        return '-';
    }
  }

  createNewOriginalValueFromHex(newValue: string): PropertyValue {
    const { case: valueCase } = this.value;
    switch (valueCase) {
      case 'valD1':
      case 'valD2':
      case 'valD4': {
        const valueWithoutDash = newValue.replace(/(?!^)-/g, ''); // Remove all dashes except the first one
        return {
          value: parseInt(valueWithoutDash, 16),
          case: valueCase,
        };
      }
      case 'valU1':
      case 'valU2':
      case 'valU4': {
        const valueWithoutDash = newValue.replace(/-/g, ''); // Remove all dashes
        return {
          value: parseInt(valueWithoutDash, 16),
          case: valueCase,
        };
      }
      case 'valText':
        return {
          value: newValue
            .split('-')
            .map((byte) => String.fromCharCode(parseInt(byte, 16)))
            .join(''),
          case: valueCase,
        };
      case 'valData': {
        const hexArray = newValue.split('-');
        const uint8Array = new Uint8Array(hexArray.length);
        for (let i = 0; i < hexArray.length; i += 1) {
          uint8Array[i] = parseInt(hexArray[i], 16);
        }
        return { value: uint8Array, case: valueCase };
      }
      case 'valServiceCommand':
      case 'valD2Fp10':
      case 'valU2Fp10':
      case 'valD2Fp100':
      case 'valU2Fp100':
      case undefined:
      default:
        return {
          case: undefined,
        };
    }
  }

  createNewOriginalValue(
    newValue: string | number | Uint8Array,
  ): PropertyValue {
    const { case: valueCase } = this.value;
    switch (valueCase) {
      case 'valD1':
      case 'valD2':
      case 'valD4':
      case 'valU1':
      case 'valU2':
      case 'valU4':
        return {
          value: parseInt(newValue.toString(), 10),
          case: valueCase,
        };

      case 'valText':
        return { value: newValue.toString(), case: valueCase };
      case 'valData':
      case 'valServiceCommand':
        return { value: newValue as Uint8Array, case: valueCase };

      case 'valD2Fp10':
      case 'valU2Fp10':
        return { value: parseFloat(newValue.toString()) * 10, case: valueCase };

      case 'valD2Fp100':
      case 'valU2Fp100':
        return {
          value: parseFloat(newValue.toString()) * 100,
          case: valueCase,
        };

      case undefined:
      default:
        return {
          case: undefined,
        };
    }
  }
}

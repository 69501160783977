import { List, Stack, Grid2 } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavItem } from '~/pages/layout/components/NavItem';
import {
  SignOutIcon,
  DevicesListIcon,
  FirmwareListIcon,
  GearIcon,
  UsersIcon,
  SupportIcon,
} from '~/shared/components/Icons/Icons';
import { useCloseSession } from '~/shared/hooks/useCloseSession';
import { useIsSignedIn } from '~/shared/auth/auth-provider';
import { createRoutes } from '~/routes';
import { RoleModel } from '~/shared/models/role/RoleModel';
import { Role } from '~/types/wavin/ignite/v1/user_pb';
import { useUserHasRequiredRole } from '~/shared/hooks/useUser';

export function DrawerContent({
  onClose,
  isMobile,
}: {
  onClose: () => void;
  isMobile: boolean;
}) {
  const { t } = useTranslation();
  const isSignedIn = useIsSignedIn();
  const closeSession = useCloseSession();
  const isAdmin = useUserHasRequiredRole(new RoleModel(Role.IAM_ADMIN));
  const isFirmwareViewer = useUserHasRequiredRole(
    new RoleModel(Role.FIRMWARE_VIEWER),
  );

  if (!isSignedIn) return null;

  return (
    <>
      <Grid2>
        <Stack
          component={List}
          onClick={() => isMobile && onClose()}
          sx={{
            gap: 6,
          }}
        >
          <NavItem
            text={t('navigation.systems')}
            path={createRoutes.Devices}
            icon={<DevicesListIcon />}
          />
          {isFirmwareViewer && (
            <NavItem
              text={t('navigation.firmware')}
              path={createRoutes.Firmware}
              icon={<FirmwareListIcon />}
            />
          )}
          {isAdmin && (
            <NavItem
              text={t('navigation.users')}
              path={createRoutes.Users}
              icon={<UsersIcon />}
            />
          )}
          <NavItem
            text={t('navigation.support')}
            path={createRoutes.Support}
            icon={<SupportIcon />}
          />
        </Stack>
      </Grid2>
      <List>
        <Grid2
          container
          sx={{
            justifyContent: 'center',
            gap: 6,
          }}
        >
          <NavItem
            text={t('navigation.settings')}
            path={createRoutes.Settings}
            icon={<GearIcon />}
          />
          <NavItem
            text={t('navigation.signOut')}
            onClick={() => closeSession()}
            icon={<SignOutIcon />}
          />
        </Grid2>
      </List>
    </>
  );
}

// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file wavin/blaze/v1/user.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file wavin/blaze/v1/user.proto.
 */
export const file_wavin_blaze_v1_user: GenFile = /*@__PURE__*/
  fileDesc("Chl3YXZpbi9ibGF6ZS92MS91c2VyLnByb3RvEg53YXZpbi5ibGF6ZS52MSIhCgRVc2VyEgoKAmlkGAEgASgJEg0KBWVtYWlsGAIgASgJQnkKEmNvbS53YXZpbi5ibGF6ZS52MUIJVXNlclByb3RvUAGiAgNXQliqAg5XYXZpbi5CbGF6ZS5WMcoCDldhdmluXEJsYXplXFYx4gIaV2F2aW5cQmxhemVcVjFcR1BCTWV0YWRhdGHqAhBXYXZpbjo6QmxhemU6OlYxYgZwcm90bzM");

/**
 * User is a reference to an identity in Identity Platform
 *
 * @generated from message wavin.blaze.v1.User
 */
export type User = Message<"wavin.blaze.v1.User"> & {
  /**
   * UID from Identity Platform
   *
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * Email from Identity Platform
   *
   * @generated from field: string email = 2;
   */
  email: string;
};

/**
 * Describes the message wavin.blaze.v1.User.
 * Use `create(UserSchema)` to create a new message.
 */
export const UserSchema: GenMessage<User> = /*@__PURE__*/
  messageDesc(file_wavin_blaze_v1_user, 0);


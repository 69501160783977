import { formatTimestamp } from '~/shared/utils/formatTimestamp';
import { Firmware, Firmware_Status } from '~/types/wavin/blaze/v1/firmware_pb';
import { User } from '~/types/wavin/blaze/v1/user_pb';

type Statuses = keyof typeof Firmware_Status;

export class FirmwareModel {
  readonly name: string;

  readonly createTime: string;

  readonly version?: string;

  readonly package?: Uint8Array;

  readonly status: Statuses;

  readonly releaseTime: string;

  readonly packageTime: string;

  readonly title?: string;

  readonly releaseNotes?: string;

  readonly uploadedBy?: User;

  readonly releasedBy?: User;

  constructor(firmware: Firmware) {
    this.name = firmware.name;
    this.createTime = formatTimestamp(firmware.createTime);
    this.version = firmware.version;
    this.package = firmware.package;
    this.status = Firmware_Status[firmware.status] as Statuses;
    this.releaseTime = formatTimestamp(firmware.releaseTime, {
      includeTime: true,
    });
    this.packageTime = formatTimestamp(firmware.packageTime, {
      includeTime: false,
    });
    this.uploadedBy = firmware.uploadedBy;
    this.releasedBy = firmware.releasedBy;
    this.title = firmware.title;
    this.releaseNotes = firmware.releaseNotes;
  }

  isFirmwareUploadedByUserEmail(email: string) {
    return this.uploadedBy?.email === email;
  }
}

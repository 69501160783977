import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRouter } from '~/router';
import { configLocales } from '~/services/i18n/i18n';
import { ENABLE_MOCKS } from '~/services/environment/environment';
import { Providers } from '~/providers';
import './globals';
import '~/BigInt';
import { initErrorCapturing } from '~/services/error-capturing/error-capturing';
import { loadCookiebarSdk } from '~/services/cookiebar/onetrust';
import { initDayjs } from './shared/utils/dayjs';

(async () => {
  const router = createRouter();

  initErrorCapturing();
  initDayjs();
  loadCookiebarSdk();

  if (ENABLE_MOCKS) {
    const { worker } = await import('~/shared/tests/mocks/msw-setup');
    await worker.start();
  }

  configLocales();

  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <Suspense>
        <Providers>
          <RouterProvider router={router} />
          <ReactQueryDevtools initialIsOpen={false} />
        </Providers>
      </Suspense>
    </React.StrictMode>,
  );
})();

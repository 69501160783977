import { ActionFunction } from 'react-router';
import { AuthService } from '~/services/auth/AuthService';
import { AuthError } from '~/services/auth/AuthError';
import { queryClient } from '~/shared/api/query-client';
import { assertIsString } from '~/types/assert-type';
import { userQuery } from '~/shared/api/user.queries';
import {
  PASSWORD_MIN_LENGTH,
  ValidationI18nError,
  yup,
} from '~/services/validation';
import { I18nKeys } from '~/types/react-i18next';

export type RegisterPasswordAction = {
  validationError?: ValidationI18nError<RegisterActionFormData>;
  authError?: AuthError;
  successFullyRegisteredPassword: boolean;
};

const SignUpScreenFormSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().min(PASSWORD_MIN_LENGTH).required(),
  confirmPassword: yup
    .string()
    .required()
    .min(PASSWORD_MIN_LENGTH)
    .oneOf([yup.ref('password')]),
});
export type RegisterActionFormData = yup.InferType<
  typeof SignUpScreenFormSchema
>;

export const registerAction =
  (auth: AuthService): ActionFunction =>
  async ({ request }): Promise<RegisterPasswordAction> => {
    const formData = await request.formData();
    const { email, password, confirmPassword } = Object.fromEntries(
      formData,
    ) as RegisterActionFormData;

    try {
      await SignUpScreenFormSchema.validate({
        email,
        password,
        confirmPassword,
      });
    } catch (error) {
      const validateError = error as yup.ValidationError;
      const errorKeys = validateError.errors as I18nKeys[];
      if (!validateError.path) {
        throw Error('Path is required');
      }
      return {
        validationError: new ValidationI18nError(
          errorKeys[0],
          validateError.path as keyof RegisterActionFormData,
        ),
        successFullyRegisteredPassword: false,
      };
    }

    assertIsString(email);

    assertIsString(password);

    assertIsString(confirmPassword);

    const registerError = await auth.register(email, password);
    await queryClient.invalidateQueries({ queryKey: userQuery().queryKey });
    if (registerError) {
      return {
        authError: registerError,
        successFullyRegisteredPassword: false,
      };
    }
    return {
      successFullyRegisteredPassword: true,
    };
  };

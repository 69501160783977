import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router';
import { listFirmwareQuery } from '~/shared/api/listFirmware.queries';
import { checkAuthAndRole } from '~/shared/auth/auth-utils';
import { FirmwareListModel } from '~/shared/models/firmwareList/FirmwareListModel';
import { RoleModel } from '~/shared/models/role/RoleModel';
import { Role } from '~/types/wavin/ignite/v1/user_pb';

export type FirmwareListPageLoaderData = FirmwareListModel;

export const firmwareListPageLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    await checkAuthAndRole(
      queryClient,
      request,
      new RoleModel(Role.FIRMWARE_VIEWER),
    );

    return await queryClient.ensureQueryData(listFirmwareQuery);
  };

import { ActionFunction } from 'react-router';
import { AuthService } from '~/services/auth/AuthService';
import { AuthError } from '~/services/auth/AuthError';
import { queryClient } from '~/shared/api/query-client';
import { assertIsString } from '~/types/assert-type';
import { userQuery } from '~/shared/api/user.queries';

export type SignInActionErrorData = AuthError | null;

export const signInAction =
  (auth: AuthService): ActionFunction =>
  async ({ request }): Promise<SignInActionErrorData> => {
    const formData = await request.formData();
    const { email, password } = Object.fromEntries(formData);

    assertIsString(email);

    assertIsString(password);

    const result = await auth.signIn(email, password);
    await queryClient.invalidateQueries({ queryKey: userQuery().queryKey });
    return result;
  };

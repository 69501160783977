// @generated by protoc-gen-es v2.2.5 with parameter "target=ts"
// @generated from file wavin/blaze/v1/device.proto (package wavin.blaze.v1, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../../buf/validate/validate_pb";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { DeviceConfig } from "./device_config_pb";
import { file_wavin_blaze_v1_device_config } from "./device_config_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file wavin/blaze/v1/device.proto.
 */
export const file_wavin_blaze_v1_device: GenFile = /*@__PURE__*/
  fileDesc("Cht3YXZpbi9ibGF6ZS92MS9kZXZpY2UucHJvdG8SDndhdmluLmJsYXplLnYxIr0JCgZEZXZpY2USHQoEbmFtZRgBIAEoCUIPukgMcgo6CGRldmljZXMvEi8KC2NyZWF0ZV90aW1lGAIgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcBIvCgt1cGRhdGVfdGltZRgDIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXASFQoNc2VyaWFsX251bWJlchgGIAEoCRIYChByZWdpc3RyYXRpb25fa2V5GAcgASgJEhoKEmZpcm13YXJlX2F2YWlsYWJsZRgIIAEoCRIaChJmaXJtd2FyZV9pbnN0YWxsZWQYCSABKAkSKQoEdHlwZRgKIAEoDjIbLndhdmluLmJsYXplLnYxLkRldmljZS5UeXBlEjgKDHJlbGVhc2VfbW9kZRgLIAEoDjIiLndhdmluLmJsYXplLnYxLkRldmljZS5SZWxlYXNlTW9kZRJLChZmaXJtd2FyZV91cGRhdGVfc3RhdHVzGAwgASgOMisud2F2aW4uYmxhemUudjEuRGV2aWNlLkZpcm13YXJlVXBkYXRlU3RhdHVzEi0KBnN0YXR1cxgNIAEoDjIdLndhdmluLmJsYXplLnYxLkRldmljZS5TdGF0dXMSMQoIcGxhdGZvcm0YDiABKA4yHy53YXZpbi5ibGF6ZS52MS5EZXZpY2UuUGxhdGZvcm0SMgoObGFzdF9oZWFydGJlYXQYFCABKAsyGi5nb29nbGUucHJvdG9idWYuVGltZXN0YW1wEjEKC2xhc3RfY29uZmlnGBUgASgLMhwud2F2aW4uYmxhemUudjEuRGV2aWNlQ29uZmlnIkYKBFR5cGUSFAoQVFlQRV9VTlNQRUNJRklFRBAAEhMKD1RZUEVfU0VOVElPX0NDVRABEhMKD1RZUEVfQ0FMRUZBX0RIVxACIkIKC1JlbGVhc2VNb2RlEhoKFlJFTEVBU0VfTU9ERV9BVVRPTUFUSUMQABIXChNSRUxFQVNFX01PREVfTUFOVUFMEAEi2QIKFEZpcm13YXJlVXBkYXRlU3RhdHVzEh8KG0ZJUk1XQVJFX1VQREFURV9TVEFUVVNfSURMRRAAEiQKIEZJUk1XQVJFX1VQREFURV9TVEFUVVNfQVZBSUxBQkxFEAESJgoiRklSTVdBUkVfVVBEQVRFX1NUQVRVU19ET1dOTE9BRElORxACEioKJkZJUk1XQVJFX1VQREFURV9TVEFUVVNfRE9XTkxPQURfRkFJTEVEEAMSKwonRklSTVdBUkVfVVBEQVRFX1NUQVRVU19SRUFEWV9UT19JTlNUQUxMEAQSIwofRklSTVdBUkVfVVBEQVRFX1NUQVRVU19VUERBVElORxAFEigKJEZJUk1XQVJFX1VQREFURV9TVEFUVVNfVVBEQVRFX0ZBSUxFRBAGEioKJkZJUk1XQVJFX1VQREFURV9TVEFUVVNfVVBEQVRFX1JFSkVDVEVEEAciLwoGU3RhdHVzEg0KCVNUQVRVU19PSxAAEhYKElNUQVRVU19VTkFWQUlMQUJMRRABIjUKCFBsYXRmb3JtEhQKEFBMQVRGT1JNX0NVUlJFTlQQABITCg9QTEFURk9STV9MRUdBQ1kQAUJ7ChJjb20ud2F2aW4uYmxhemUudjFCC0RldmljZVByb3RvUAGiAgNXQliqAg5XYXZpbi5CbGF6ZS5WMcoCDldhdmluXEJsYXplXFYx4gIaV2F2aW5cQmxhemVcVjFcR1BCTWV0YWRhdGHqAhBXYXZpbjo6QmxhemU6OlYxYgZwcm90bzM", [file_buf_validate_validate, file_google_protobuf_timestamp, file_wavin_blaze_v1_device_config]);

/**
 * The physical device which acts as the controller for the device and connected peripherals.
 *
 * @generated from message wavin.blaze.v1.Device
 */
export type Device = Message<"wavin.blaze.v1.Device"> & {
  /**
   * Device resource name, format: devices/{device}
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 2;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 3;
   */
  updateTime?: Timestamp;

  /**
   * @generated from field: string serial_number = 6;
   */
  serialNumber: string;

  /**
   * @generated from field: string registration_key = 7;
   */
  registrationKey: string;

  /**
   * i.e. 1.0.0
   *
   * @generated from field: string firmware_available = 8;
   */
  firmwareAvailable: string;

  /**
   * i.e. 1.0.0
   *
   * @generated from field: string firmware_installed = 9;
   */
  firmwareInstalled: string;

  /**
   * @generated from field: wavin.blaze.v1.Device.Type type = 10;
   */
  type: Device_Type;

  /**
   * @generated from field: wavin.blaze.v1.Device.ReleaseMode release_mode = 11;
   */
  releaseMode: Device_ReleaseMode;

  /**
   * @generated from field: wavin.blaze.v1.Device.FirmwareUpdateStatus firmware_update_status = 12;
   */
  firmwareUpdateStatus: Device_FirmwareUpdateStatus;

  /**
   * @generated from field: wavin.blaze.v1.Device.Status status = 13;
   */
  status: Device_Status;

  /**
   * @generated from field: wavin.blaze.v1.Device.Platform platform = 14;
   */
  platform: Device_Platform;

  /**
   * Most recent heartbeat
   *
   * @generated from field: google.protobuf.Timestamp last_heartbeat = 20;
   */
  lastHeartbeat?: Timestamp;

  /**
   * Most recent device config
   *
   * @generated from field: wavin.blaze.v1.DeviceConfig last_config = 21;
   */
  lastConfig?: DeviceConfig;
};

/**
 * Describes the message wavin.blaze.v1.Device.
 * Use `create(DeviceSchema)` to create a new message.
 */
export const DeviceSchema: GenMessage<Device> = /*@__PURE__*/
  messageDesc(file_wavin_blaze_v1_device, 0);

/**
 * @generated from enum wavin.blaze.v1.Device.Type
 */
export enum Device_Type {
  /**
   * @generated from enum value: TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TYPE_SENTIO_CCU = 1;
   */
  SENTIO_CCU = 1,

  /**
   * @generated from enum value: TYPE_CALEFA_DHW = 2;
   */
  CALEFA_DHW = 2,
}

/**
 * Describes the enum wavin.blaze.v1.Device.Type.
 */
export const Device_TypeSchema: GenEnum<Device_Type> = /*@__PURE__*/
  enumDesc(file_wavin_blaze_v1_device, 0, 0);

/**
 * @generated from enum wavin.blaze.v1.Device.ReleaseMode
 */
export enum Device_ReleaseMode {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * firmware releases are made automatic available
   *
   * @generated from enum value: RELEASE_MODE_AUTOMATIC = 0;
   */
  AUTOMATIC = 0,

  /**
   * firmware releases are made manual available
   *
   * @generated from enum value: RELEASE_MODE_MANUAL = 1;
   */
  MANUAL = 1,
}

/**
 * Describes the enum wavin.blaze.v1.Device.ReleaseMode.
 */
export const Device_ReleaseModeSchema: GenEnum<Device_ReleaseMode> = /*@__PURE__*/
  enumDesc(file_wavin_blaze_v1_device, 0, 1);

/**
 * @generated from enum wavin.blaze.v1.Device.FirmwareUpdateStatus
 */
export enum Device_FirmwareUpdateStatus {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * no update available or finished updating
   *
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_IDLE = 0;
   */
  IDLE = 0,

  /**
   * update available for download to device
   *
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_AVAILABLE = 1;
   */
  AVAILABLE = 1,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_DOWNLOADING = 2;
   */
  DOWNLOADING = 2,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_DOWNLOAD_FAILED = 3;
   */
  DOWNLOAD_FAILED = 3,

  /**
   * update stored on device, ready to install
   *
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_READY_TO_INSTALL = 4;
   */
  READY_TO_INSTALL = 4,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UPDATING = 5;
   */
  UPDATING = 5,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UPDATE_FAILED = 6;
   */
  UPDATE_FAILED = 6,

  /**
   * @generated from enum value: FIRMWARE_UPDATE_STATUS_UPDATE_REJECTED = 7;
   */
  UPDATE_REJECTED = 7,
}

/**
 * Describes the enum wavin.blaze.v1.Device.FirmwareUpdateStatus.
 */
export const Device_FirmwareUpdateStatusSchema: GenEnum<Device_FirmwareUpdateStatus> = /*@__PURE__*/
  enumDesc(file_wavin_blaze_v1_device, 0, 2);

/**
 * @generated from enum wavin.blaze.v1.Device.Status
 */
export enum Device_Status {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: STATUS_OK = 0;
   */
  OK = 0,

  /**
   * @generated from enum value: STATUS_UNAVAILABLE = 1;
   */
  UNAVAILABLE = 1,
}

/**
 * Describes the enum wavin.blaze.v1.Device.Status.
 */
export const Device_StatusSchema: GenEnum<Device_Status> = /*@__PURE__*/
  enumDesc(file_wavin_blaze_v1_device, 0, 3);

/**
 * @generated from enum wavin.blaze.v1.Device.Platform
 */
export enum Device_Platform {
  /**
   * buf:lint:ignore ENUM_ZERO_VALUE_SUFFIX
   *
   * @generated from enum value: PLATFORM_CURRENT = 0;
   */
  CURRENT = 0,

  /**
   * Legacy implies a required platform update
   *
   * @generated from enum value: PLATFORM_LEGACY = 1;
   */
  LEGACY = 1,
}

/**
 * Describes the enum wavin.blaze.v1.Device.Platform.
 */
export const Device_PlatformSchema: GenEnum<Device_Platform> = /*@__PURE__*/
  enumDesc(file_wavin_blaze_v1_device, 0, 4);


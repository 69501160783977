import * as yup from 'yup';
import { I18nKeys } from '~/types/react-i18next';

export const PASSWORD_MIN_LENGTH = 8;
export const REGISTER_KEY_LENGTH = 16;
export const SERIAL_NUMBER_LENGTH = 17;
export const ACCESS_KEY_LENGTH = 19;
export const ACCESS_CODE_LENGTH = 4;
export const INPUT_MAX_LENGTH = 100;

export class ValidationI18nError<T> extends Error {
  constructor(
    public readonly key: I18nKeys,
    public readonly path: keyof T,
  ) {
    super(key);
  }
}

/*
  t('validation.required')
  t('validation.email')
  t('validation.maxLength')
  t('validation.minLength')
  t('validation.mustMatch')
  t('validation.required')
*/

yup.setLocale({
  mixed: {
    required: 'validation.required',
    oneOf: 'validation.mustMatch',
  },
  string: {
    min: 'validation.minLength',
    max: 'validation.maxLength',
    email: 'validation.email',
  },
  number: {
    min: 'validation.minLength',
    max: 'validation.maxLength',
  },
});

export { yup };

import { ActionFunction } from 'react-router';
import { AuthService } from '~/services/auth/AuthService';
import { AuthError } from '~/services/auth/AuthError';
import {
  PASSWORD_MIN_LENGTH,
  ValidationI18nError,
  yup,
} from '~/services/validation';
import { I18nKeys } from '~/types/react-i18next';
import { assertIsString } from '~/types/assert-type';

export type ChangePasswordAction = {
  validationError?: ValidationI18nError<ChangePasswordFormData>;
  authError?: AuthError;
  successFullyChangedPassword: boolean;
};

const ChangePasswordFormSchema = yup.object({
  currentPassword: yup.string().required(),
  newPassword: yup.string().min(PASSWORD_MIN_LENGTH).required(),
  confirmPassword: yup
    .string()
    .required()
    .min(PASSWORD_MIN_LENGTH)
    .oneOf([yup.ref('newPassword')]),
});
export type ChangePasswordFormData = yup.InferType<
  typeof ChangePasswordFormSchema
>;

export const changePasswordAction =
  (auth: AuthService): ActionFunction =>
  async ({ request }): Promise<ChangePasswordAction> => {
    const formData = await request.formData();
    const { currentPassword, confirmPassword, newPassword } =
      Object.fromEntries(formData) as ChangePasswordFormData;

    try {
      await ChangePasswordFormSchema.validate({
        currentPassword,
        newPassword,
        confirmPassword,
      });
    } catch (error) {
      const validateError = error as yup.ValidationError;
      const errorKeys = validateError.errors as I18nKeys[];
      if (!validateError.path) {
        throw Error('Path is required');
      }
      return {
        validationError: new ValidationI18nError(
          errorKeys[0],
          validateError.path as keyof ChangePasswordFormData,
        ),
        successFullyChangedPassword: false,
      };
    }

    assertIsString(currentPassword);
    assertIsString(newPassword);

    const result = await auth.changePassword(currentPassword, newPassword);
    if (result) {
      return { authError: result, successFullyChangedPassword: false };
    }
    return {
      successFullyChangedPassword: true,
    };
  };

import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/nl';

export const initDayjs = () => {
  dayjs.extend(isSameOrAfter);
  dayjs.extend(localeData);
};

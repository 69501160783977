import { queryOptions } from '@tanstack/react-query';
import { createNameFromDeviceId } from '~/shared/models/id-utils';
import { DeviceClaimsListModel } from '~/pages/Device/models/DeviceClaimsListModel';
import { blazeDevicePromiseClient } from '~/services/blaze-device-client';
import { BlazeDeviceQueryKey } from '~/services/queryKeys';

export const listDeviceClaimsQuery = (deviceId: string) =>
  queryOptions({
    queryKey: [BlazeDeviceQueryKey.LIST_DEVICE_CLAIMS, deviceId],
    queryFn: async () => {
      const response = await blazeDevicePromiseClient.listDeviceClaims({
        parent: createNameFromDeviceId(deviceId),
        pageSize: BigInt(1000),
      });
      return new DeviceClaimsListModel(response);
    },
  });

import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs } from 'react-router';
import { listDeviceAccessKeysQuery } from '~/pages/Device/api/listDeviceAccessKeys.queries';
import { DeviceAccessKeysListModel } from '~/pages/Device/models/DeviceAccessKeysListModel';
import { checkAuth } from '~/shared/auth/auth-utils';
import { assertIsNonBlankString } from '~/types/assert-type';

export type DeviceAccessKeysLoaderData = DeviceAccessKeysListModel;

export const deviceAccessKeysLoader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    await checkAuth(request);

    const { deviceId } = params;

    assertIsNonBlankString(deviceId);

    return await queryClient.ensureQueryData(
      listDeviceAccessKeysQuery({ deviceId }),
    );
  };

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { PropsWithChildren, useEffect } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { useCookiebar } from '~/pages/layout/hooks/useCookiebar';
import { setErrorCapturingConsent } from '~/services/error-capturing/error-capturing';
import { theme } from '~/shared/theme/theme';
import { queryClient } from '~/shared/api/query-client';
import { AuthProvider } from '~/shared/auth/auth-provider';

export function Providers({ children }: PropsWithChildren) {
  const consentedCookieGroups = useCookiebar();

  useEffect(() => {
    setErrorCapturingConsent(consentedCookieGroups.performance);
  }, [consentedCookieGroups.performance]);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <AuthProvider>{children}</AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
